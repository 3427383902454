import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from '../RadioButton';

const RadioButtonField = ({ input, className, ...rest }) => {
  return <RadioButton className={className} type="radio" {...rest} {...input} />;
};

RadioButtonField.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
};
RadioButtonField.defaultProps = {
  className: undefined,
};
export default RadioButtonField;
