import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const RadioGroup = ({ label, inline, children }) => {
  const baseClass = 'ickyc-radio-group';
  const classses = classNames({
    [baseClass]: true,
    [`${baseClass}--inline`]: inline,
  });

  return (
    <div className={classses}>
      {label && <label>{label}</label>}
      <div className={`${baseClass}__content`}>{children}</div>
    </div>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
RadioGroup.defaultProps = {
  label: undefined,
  inline: false,
};
export default RadioGroup;
