import api from 'api';
import DocumentDropzone from 'components/DocumentsAndCustomQuestions/components/DocumentDropzone';
import DocumentDropzoneField from 'components/DocumentsAndCustomQuestions/components/DocumentDropzoneField';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import './styles.scss';
const PackageItemsFields = ({ packageId }) => {
  const [packageItems, setPackageItems] = useState([]);
  const [isDocumentTypesLoading, setIsDocumentTypesLoading] = useState(false);

  useEffect(() => {
    const fetchPackageItems = async () => {
      try {
        setIsDocumentTypesLoading(true);
        const {
          data: { data },
        } = await api.administration.supportingDocumentPackages.getSupportingDocumentPackagesItems({
          packageId,
        });

        setPackageItems(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsDocumentTypesLoading(false);
      }
    };

    fetchPackageItems();
  }, [packageId]);

  const prepareFileTypes = useCallback(parameters => {
    const transformedParameters = JSON.parse(parameters);

    return Object.entries(transformedParameters).reduce((acc, [key, value]) => {
      if (value && key.startsWith('restrictTo')) {
        const extension = `.${key.slice(10).toLowerCase()}`;
        acc.push(extension);
      }
      return acc;
    }, []);
  }, []);

  return (
    <div className="ickyc-package-items-fields">
      {packageItems?.map((item, index) => {
        return (
          <div>
            <Field
              name={`items.${item.id}`}
              validate={item.isRequired && validators.required(' ')}
              component={DocumentDropzoneField}
              required={item.isRequired}
              label={`${index + 1}. ${item.description}`}
              uploadText="Upload Document"
              key={item.id}
              accept={prepareFileTypes(item.parameters)}
            />
            {item.documentFullPath && (
              <DocumentDropzone
                key={item.id + item.downloadUrl}
                value={[{ originalName: 'Download Template', blobUrl: item.documentFullPath }]}
                preview
                downloadText="Download Template"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default PackageItemsFields;
