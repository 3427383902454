import classNames from 'classnames';
import Accordion from 'components/Accordion';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThirdPartyConfirmation from 'scenes/Kyc/EntityManagement/LegalEntityProfile/components/ThirdPartyConfirmation';
import IssueList from 'scenes/Kyc/EntityManagement/components/IssueList';
import { DocumentDataContext } from 'utilities/services/contexts';
import DocumentsPackageTable from '../DocumentsPackageTable';
import './styles.scss';

function DocumentPackage() {
  const { documentRecord, setDocumentRecord, onRemove } = useContext(DocumentDataContext);

  const classes = classNames({
    'ickyc-document-package': true,
    'ickyc-document-package--preview': true,
  });

  return (
    <Accordion title="Document Package" accordionOpen className={classes}>
      <IssueList list={documentRecord?.issues} />

      <DocumentsPackageTable items={documentRecord?.items} />
      {documentRecord?.thirdPartySuggestion && (
        <ThirdPartyConfirmation
          data={documentRecord}
          onDataFetch={setDocumentRecord}
          onRemove={onRemove}
          isSupportingDocument
        />
      )}
    </Accordion>
  );
}
DocumentPackage.propTypes = {
  documentPackage: PropTypes.shape({}),
};
DocumentPackage.defaultProps = {
  documentPackage: {},
};

export default DocumentPackage;
