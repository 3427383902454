/* eslint-disable react/jsx-indent-props */
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import api from 'api';
import CheckIcon from 'assets/CheckIcon';
import HourglassIcon from 'assets/HourglassIcon';
import classNames from 'classnames';
import Badge from 'components/Badge';
import Loader from 'components/Loader';
import ProcessingBadge from 'components/ProcessingBadge';
import TagsContainer from 'components/TagButtonContainer';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import ProtectedComponent from 'router/components/ProtectedComponent';
import toastr from 'toastr';
import utilities from 'utilities';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';
import { PermissionGroupContext } from 'utilities/services/contexts';
import BatchDeleteButton from '../../../EntityManagement/components/BatchDeleteButton';
import BatchAtachUsersToCaseModal from '../BatchAtachUsersToCaseModal';
import BatchOngoingStatusToggle from '../BatchOngoingStatusToggle';
import BatchStatusToggle from '../BatchStatusToggle';

const CasesTableRow = ({
  rowInfo: {
    id,
    created,
    attachedTo = [],
    name,
    runBy,
    ongoing,
    potential,
    caseStatus,
    commentsCount,
    checked,
    processing,
  },
  className,
  onRemove,
  onCheckboxClick,
  updatingRows,
  setUpdatingRows,
  onBatchAction,
}) => {
  const { isOpen: isAttachModalOpen, close: closeAttachmentModal, open: openAttachmentModal } = useModalHandler();
  const accessByLicence = useSelector(state => state.auth.personalInfo.accessByLicence);
  const includesOngoingMonitoring = useMemo(() => accessByLicence.includes('Ongoing Monitoring'), [accessByLicence]);
  const navigateToCaseDetails = useMemo(() => {
    return utilities.routeParams(routesEnum.kyc.CASE_DETAIL, {
      id,
    });
  }, [id]);

  const ongoingMonitoringTooltip = useMemo(
    () =>
      includesOngoingMonitoring
        ? 'Once you disable ongoing monitoring, you can not re-enable it again.'
        : 'Ongoing monitoring cannot be changed due to license restrictions.',
    [includesOngoingMonitoring],
  );

  const detachUser = entity => {
    setUpdatingRows([id]);
    api.kyc.caseManagement
      .removeEntityFromCaseApi(id, entity?.id)
      .then(({ data }) => {
        toastr.success(`Entity: ${entity.name}is remove from case`);
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data);
        onBatchAction([
          {
            id,
            created,
            name,
            runBy,
            ongoing,
            potential,
            caseStatus,
            commentsCount,
            checked: false,
            attachedTo: attachedTo.filter(elm => elm?.id !== entity?.id),
          },
        ]);
      })
      .finally(() => {
        setUpdatingRows([]);
      });
  };

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const history = useHistory();

  const navigateToEntityProfile = ({ type, id: eId }) => {
    history.push(
      utilities.routeParams(type ? routesEnum.kyc.LEGAL_ENTITY : routesEnum.kyc.NATURAL_PERSON, { id: eId }),
    );
  };
  const { linkEntities: canLinkEntities, ongoingMonitoring: canOngoingMonitoring, status: canStatus } = useContext(
    PermissionGroupContext,
  );

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(id);
  }, [id, onCheckboxClick]);

  const classes = classNames(`${className}__row`, { [`${className}__row--disabled`]: processing });
  return (
    <tr key={id} className={classes}>
      {!!updatingRows.length && updatingRows.includes(id) && <Loader />}
      <td className={setColumnSuffix('__checkbox')}>
        <input type="checkbox" checked={checked} readOnly onChange={handleCheckBoxClick} />
      </td>
      <td className={setColumnSuffix('__created')}>
        {processing ? (
          <Tooltip
            content={<span>Retrieving case results</span>}
            trigger={
              <div>
                <HourglassIcon className={setColumnSuffix('__created__pending-icon')} />
              </div>
            }
          />
        ) : (
          <Tooltip
            content={<span>Ready</span>}
            trigger={
              <div>
                <CheckIcon className={setColumnSuffix('__created__ready-icon')} />
              </div>
            }
          />
        )}
        <NavLink to={navigateToCaseDetails}>{DateManager.monDayYearLocal(created)}</NavLink>
      </td>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <td className={setColumnSuffix('__attachedTo')}>
          <Tooltip
            placement="top"
            trigger={
              <div className={setColumnSuffix('__attachedTo__tooltip')}>
                {isAttachModalOpen && (
                  <BatchAtachUsersToCaseModal
                    initialAttachments={attachedTo.map(({ id: aeId, name: aeName }) => ({ id: aeId, label: aeName }))}
                    hideModal={closeAttachmentModal}
                    complianceLogId={id}
                    onBatchAction={onBatchAction}
                  />
                )}
                <TagsContainer
                  options={attachedTo}
                  onRemove={detachUser}
                  onAdd={openAttachmentModal}
                  onClick={navigateToEntityProfile}
                  preview={!canLinkEntities}
                  showMoreAfterNumberOfTags={2}
                />
              </div>
            }
            content={<span>Attach a KYC Subject to this Case</span>}
          />
          <NavLink to={navigateToCaseDetails} />
        </td>
      </ProtectedComponent>
      <td className={setColumnSuffix('__name')}>
        <NavLink to={navigateToCaseDetails}>{name}</NavLink>
      </td>
      <td className={setColumnSuffix('__runby')}>
        <NavLink to={navigateToCaseDetails}>{runBy}</NavLink>
      </td>
      {canOngoingMonitoring && (
        <td className={setColumnSuffix('__ongoing')}>
          {!ongoing ? (
            <>
              <BatchOngoingStatusToggle
                toggleState={ongoing}
                setUpdating={setUpdatingRows}
                id={id}
                onBatchAction={onBatchAction}
              />
              <NavLink to={navigateToCaseDetails} />
            </>
          ) : (
            <Tooltip
              placement="top"
              content={<span>{ongoingMonitoringTooltip}</span>}
              trigger={
                <div>
                  <BatchOngoingStatusToggle
                    toggleState={ongoing}
                    setUpdating={setUpdatingRows}
                    id={id}
                    onBatchAction={onBatchAction}
                    disabled={!includesOngoingMonitoring}
                  />
                  <NavLink to={navigateToCaseDetails} />
                </div>
              }
            />
          )}
        </td>
      )}
      {canStatus && (
        <td className={setColumnSuffix('__status')}>
          <BatchStatusToggle
            id={id}
            toggleState={caseStatus === 'Open'}
            setUpdating={setUpdatingRows}
            onBatchAction={onBatchAction}
          />
          <NavLink to={navigateToCaseDetails} />
        </td>
      )}
      <td className={setColumnSuffix('__potential')}>
        <NavLink to={navigateToCaseDetails}>
          <Tooltip
            placement="top"
            trigger={<div>{potential === null ? <ProcessingBadge /> : <Badge red content={potential} />}</div>}
            content={<span>Potential Matches</span>}
          />
        </NavLink>
      </td>
      <td className={setColumnSuffix('__comments')}>
        <NavLink to={navigateToCaseDetails}>
          {commentsCount > 0 && (
            <>
              <h3>{commentsCount}</h3>
              <ChatBubbleIcon />
            </>
          )}
        </NavLink>
      </td>
      <td className={setColumnSuffix('__remove')}>
        <BatchDeleteButton
          entityId={id}
          onRemove={onRemove}
          apiRequest={api.kyc.caseManagement.batchDeleteCases}
          cases
        />
      </td>
    </tr>
  );
};

CasesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string,
    attachedTo: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    name: PropTypes.string.isRequired,
    runBy: PropTypes.string.isRequired,
    ongoing: PropTypes.bool,
    potential: PropTypes.number,
    caseStatus: PropTypes.string,
    commentsCount: PropTypes.number,
    checked: PropTypes.bool,
    processing: PropTypes.bool,
  }),
  updatingRows: PropTypes.array,
  setUpdatingRows: PropTypes.func,
  className: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  onBatchAction: PropTypes.func,
};

CasesTableRow.defaultProps = {
  onRemove: () => {},
  rowInfo: {
    attachedTo: [],
    created: '',
    potential: 0,
    ongoing: false,
    commentsCount: 0,
    checked: false,
    processing: false,
  },
  updatingRows: [],
  setUpdatingRows: () => {},
  onCheckboxClick: () => {},
  onBatchAction: () => {},
};

export default CasesTableRow;
