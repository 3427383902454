import axiosInstance from '../../../axiosInstance';

const BASE_ROUTE = '/entities/natural-persons';
const ENDPOINTS = {
  getBiometricTable: entityId => `${BASE_ROUTE}/${entityId}/biometrics`,
  getBiometricTableRecord: (eventId, entityId) => `${BASE_ROUTE}/${entityId}/biometrics/${eventId}`,
  updateBiometricDataDetails: (eventId, entityId) =>
    `${BASE_ROUTE}/${entityId}/biometrics/${eventId}/biometric-details`,
  generateBiometricPDFSummary: (eventId, entityId) => `${BASE_ROUTE}/${entityId}/biometrics/${eventId}/summary`,

  getIdentityDocumentsTable: entityId => `${BASE_ROUTE}/${entityId}/identity-documents`,
  getIdentityDocumentsTableRecord: (entityId, documentId) =>
    `${BASE_ROUTE}/${entityId}/identity-documents/${documentId}`,
  getIdentityRecordCroppedImage: (entityId, documentId) =>
    `${BASE_ROUTE}/${entityId}/identity-documents/${documentId}/crop-face-image`,
  updateIdentityRecordDetails: (entityId, documentId) =>
    `${BASE_ROUTE}/${entityId}/identity-documents/${documentId}/document-details`,
  createIdentityDocumentsTableRecord: entityId => `${BASE_ROUTE}/${entityId}/identity-documents/document-verification`,
  checkUploadValidity: entityId => `${BASE_ROUTE}/${entityId}/identity-documents/is-upload-valid`,
  generatePdfSummary: (entityId, eventId) => `${BASE_ROUTE}/${entityId}/identity-documents/${eventId}/summary`,

  getNaturalPersonProfile: entityId => `${BASE_ROUTE}/${entityId}`,
  getInformationTable: entityId => `${BASE_ROUTE}/${entityId}/personal-information`,
  getInformationTableRecord: (eventId, entityId) => `${BASE_ROUTE}/${entityId}/personal-information/${eventId}`,
  updateInformation: entityId => `${BASE_ROUTE}/${entityId}`,

  importNP_CSV: () => `${BASE_ROUTE}/import-csv`,

  uploadFaceImage: entityId => `${BASE_ROUTE}/${entityId}/biometrics/selfie-image`,
  checkFaceImageUploadValidity: entityId => `${BASE_ROUTE}/${entityId}/biometrics/is-upload-valid`,

  uploadFile: () => `${BASE_ROUTE}/identity-documents/upload`,
  getDocumentData: () => `${BASE_ROUTE}/identity-documents/scan`,
};
const createNew = body => axiosInstance.post(BASE_ROUTE, body);

const updateInformation = (entityId, data) => {
  const formdata = new FormData();

  formdata.append('personalInformation', JSON.stringify(data.personalInformation));
  formdata.append('documentEvent', JSON.stringify(data.documentEvent) || null);
  formdata.append('status', data.status);

  if (data.items) {
    Object.entries(data.items).forEach(([itemId, files]) => {
      files &&
        files.map(({ file }) => {
          formdata.append('itemIds', itemId);
          formdata.append('files', file);
        });
    });
  }

  return axiosInstance.put(ENDPOINTS.updateInformation(entityId), formdata);
};

const getBiometricTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getBiometricTable(entityId), { params });
};

const getBiometricTableRecord = (eventId, entityId) =>
  axiosInstance.get(ENDPOINTS.getBiometricTableRecord(eventId, entityId));

const updateBiometricDataDetails = (entityId, documentId, data) => {
  return axiosInstance.put(ENDPOINTS.updateBiometricDataDetails(entityId, documentId), data);
};

const getIdentityDocumentsTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getIdentityDocumentsTable(entityId), { params });
};

const getIdentityDocumentsTableRecord = (entityId, documentId) => {
  return axiosInstance.get(ENDPOINTS.getIdentityDocumentsTableRecord(entityId, documentId));
};
const getIdentityRecordCroppedImage = (entityId, documentId) => {
  return axiosInstance.get(ENDPOINTS.getIdentityRecordCroppedImage(entityId, documentId));
};

const updateIdentityRecordDetails = (entityId, documentId, data) => {
  return axiosInstance.put(ENDPOINTS.updateIdentityRecordDetails(entityId, documentId), data);
};

const createIdentityRecord = (entityId, data) => {
  return axiosInstance.post(ENDPOINTS.createIdentityDocumentsTableRecord(entityId), data);
};

const getNaturalPersonProfile = entityId => {
  return axiosInstance.get(ENDPOINTS.getNaturalPersonProfile(entityId));
};

const getInformationTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getInformationTable(entityId), { params });
};

const getInformationTableRecord = (eventId, entityid) => {
  return axiosInstance.get(ENDPOINTS.getInformationTableRecord(eventId, entityid));
};

const checkDocumentUploadValidity = entityId => {
  return axiosInstance.get(ENDPOINTS.checkUploadValidity(entityId));
};

const importCSV = file => {
  return axiosInstance.post(ENDPOINTS.importNP_CSV(), file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  });
};

const uploadFaceImage = (entityId, data, forceRequest) => {
  const params = forceRequest ? {} : {};
  return axiosInstance.post(ENDPOINTS.uploadFaceImage(entityId), data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params,
  });
};

const checkFaceImageUploadValidity = entityId => {
  return axiosInstance.get(ENDPOINTS.checkFaceImageUploadValidity(entityId));
};

const generatePdfSummary = (entityId, eventId, data) => {
  return axiosInstance.post(ENDPOINTS.generatePdfSummary(entityId, eventId), data, {
    responseType: 'blob',
  });
};
const generateBiometricPDFSummary = (entityId, eventId, data) => {
  return axiosInstance.post(ENDPOINTS.generateBiometricPDFSummary(eventId, entityId), data, {
    responseType: 'blob',
  });
};
const uploadFile = data => {
  return axiosInstance.post(ENDPOINTS.uploadFile(), data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const getDocumentData = data => {
  return axiosInstance.post(ENDPOINTS.getDocumentData(), data);
};

export default {
  createNew,
  updateInformation,
  getNaturalPersonProfile,
  getInformationTable,
  getInformationTableRecord,
  getBiometricTable,
  getBiometricTableRecord,
  updateBiometricDataDetails,
  getIdentityDocumentsTable,
  getIdentityDocumentsTableRecord,
  getIdentityRecordCroppedImage,
  updateIdentityRecordDetails,
  createIdentityRecord,
  importCSV,
  checkDocumentUploadValidity,
  uploadFaceImage,
  checkFaceImageUploadValidity,
  generatePdfSummary,
  generateBiometricPDFSummary,
  uploadFile,
  getDocumentData,
};
