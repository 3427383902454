import { ArrowRight } from '@material-ui/icons';
import DocumentOptions from 'components/DocumentsAndCustomQuestions/components/DocumentOptions';
import { NEW_PACKAGE_ITEM_ID } from 'components/DocumentsAndCustomQuestions/utils';
import IComplyForm from 'components/Form/IComplyForm';
import FormModal from 'components/Modal/FormModal';
import ToggleField from 'components/ToggleField';
import arrayMutators from 'final-form-arrays';
import React, { useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';
import CollapsibleTableRow from 'scenes/Kyc/components/CollapsibleTableRow';
import setClassSuffix from 'utilities/services/ClassManager';
import DeleteButton from '../../../Buttons/DeleteButton';
import CustomQuestionOptions from '../CustomQuestionOptions';
import DocumentColumns from './components/DocumentColumns';
import FormActions from './components/FormActions';
import QuestionColumns from './components/QuestionColumns';
import useDocumentsAndCustomQuestionsHandlers from './hooks/useDocumentsAndCustomQuestionsHandlers';
import './styles.scss';
import utils from './utils';

const RenderCustomQuestionOptions = () => {
  const {
    input: { value: answerFormat },
  } = useField('type');

  return <CustomQuestionOptions answerFormat={answerFormat} />;
};

const DocumentsAndCustomQuestionsTableRow = ({ rowInfo, index, onRemove, updateRowData }) => {
  const { type, id } = rowInfo;
  const { stopPropagation } = utils;
  const [isUpdating, setisUpdating] = useState(false);

  const { handleSubmit, handleRemove, forceDelete, setForceDelete } = useDocumentsAndCustomQuestionsHandlers(
    rowInfo,
    updateRowData,
    onRemove,
    setisUpdating,
  );

  const baseClass = 'ickyc-documents-and-custom-questions-table-row';
  const setSuffix = setClassSuffix(baseClass);

  const preparedValues = useMemo(() => {
    const { documentFullPath, documentOriginalName, ...rest } = rowInfo;
    return {
      ...rest,
      document: documentFullPath && documentOriginalName && { blobUrl: documentFullPath, name: documentOriginalName },
    };
  }, [rowInfo]);

  return (
    <div className={baseClass}>
      <IComplyForm
        initialValues={preparedValues}
        mutators={{ ...arrayMutators }}
        onSubmit={handleSubmit}
        isUpdating={isUpdating}
      >
        {({ dirty, submitSucceeded }) => (
          <CollapsibleTableRow
            externalArrow
            isToggled={!submitSucceeded}
            index={index + 1}
            key={index}
            shouldExpand={id === NEW_PACKAGE_ITEM_ID || dirty}
            className="ickyc-documents-and-custom-questions-table-row"
            isDraggable={id !== NEW_PACKAGE_ITEM_ID}
            isDropAllowed={id !== NEW_PACKAGE_ITEM_ID}
            row={
              <div>
                <td className={setSuffix('__column__index')}>
                  <h3>{index + 1}</h3>
                </td>
                {type === 'Document' ? (
                  <DocumentColumns setSuffix={setSuffix} />
                ) : (
                  <QuestionColumns setSuffix={setSuffix} index={index} />
                )}

                <td className={setSuffix('__required')} onClick={stopPropagation}>
                  <Field name="isRequired" component={ToggleField} />
                </td>
                <td className={setSuffix('__remove')}>
                  <DeleteButton onClick={handleRemove} />
                </td>
                <td className={setSuffix('__new-line-collapse')}>
                  <ArrowRight />
                  <span>{`${type === 'Document' ? 'Document' : 'Answer'} Options`}</span>
                  <span />
                </td>
              </div>
            }
          >
            {type === 'Document' ? <DocumentOptions /> : <RenderCustomQuestionOptions />}
            <FormActions />
          </CollapsibleTableRow>
        )}
      </IComplyForm>
      {forceDelete && (
        <FormModal
          hideModal={() => setForceDelete(false)}
          title="Confirm deletion of supporting document"
          className="ickyc-confirmation-modal"
          onSubmit={() => handleRemove(undefined, forceDelete)}
          submitButtonText="Confirm"
        >
          <span className="ickyc-confirmation-message">
            This document type is connected to supporting documents and deleting it will remove it from all places.
            Would you like to proceed?
          </span>
        </FormModal>
      )}
    </div>
  );
};
export default DocumentsAndCustomQuestionsTableRow;
