import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import './styles.scss';

const DocumentOptions = () => {
  const {
    values: { document },
  } = useFormState();
  const { change } = useForm();
  const [disableTypes, setDisableTypes] = useState(false);

  const turnOffUnmachedRestrictions = useCallback(
    documentExtention => {
      const restrictionNames = [
        'parameters.restrictToJPEG',
        'parameters.restrictToPNG',
        'parameters.restrictToPDF',
        'parameters.restrictToDOC',
      ];

      restrictionNames.forEach(el =>
        el.toLowerCase().includes(documentExtention) ? change(el, true) : change(el, false),
      );
    },
    [change],
  );

  useEffect(() => {
    if (document) {
      const extention = document.name.split('.')[1];
      turnOffUnmachedRestrictions(extention);
      setDisableTypes(true);
    } else {
      setDisableTypes(false);
    }
  }, [document, turnOffUnmachedRestrictions]);

  return (
    <div className="ickyc-custom-document-options">
      <span className="ickyc-custom-document-options--label">Document ID</span>
      <span>
        <Field name="id" component={InputField} preview></Field>
      </span>
      <span className="ickyc-custom-document-options--label">Restrict File Types To</span>
      <span className="ickyc-custom-document-options--file-types">
        <Field name="parameters.restrictToJPEG" label="JPEG" component={ToggleField} disabled={disableTypes} />
        <Field name="parameters.restrictToPNG" label="PNG" component={ToggleField} disabled={disableTypes} />
        <Field name="parameters.restrictToPDF" label="PDF" component={ToggleField} disabled={disableTypes} />
        <Field name="parameters.restrictToDOC" label="DOC" component={ToggleField} disabled={disableTypes} />
      </span>
      {/* First phase of supporting documents doesn't have re-upload */}
      {/* <span className="ickyc-custom-document-options--label">Require Subject to Re-upload</span>
      <span>
        <Field name="parameters.requireReupload" component={ToggleField} />
      </span> */}
    </div>
  );
};
export default DocumentOptions;
