import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import enums from 'utilities/enums';
import api from '../../../../../api';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import TextareaField from '../../../../../components/TextareaField';
import ToggleField from '../../../../../components/ToggleField';
import validators from '../../../../../utilities/services/validators';
import PackageItemsFields from './components/PackageItemsFields';
import './index.scss';

function AdditionalDocumentsForm({ noToggle, namePreffix, noStatus }) {
  const [supportingPackages, setSupportingPackages] = useState([]);
  const [isDocumentTypesLoading, setIsDocumentTypesLoading] = useState(false);
  const { values } = useFormState();
  const { change } = useForm();
  const { packageId } = values[namePreffix] || {};

  const { files } = values;

  const { pathname } = useLocation();

  const pathnameEntityType = useMemo(
    () =>
      pathname?.includes('legal-entity')
        ? enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY
        : enums.ENTITY_TYPE_NAMES.NATURAL_PERSON,
    [pathname],
  );

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.HISTORY_EVENT_STATUSES_REDUCED).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  useEffect(() => {
    if (!files) return;

    const documentName = files?.length && files?.length > 0 ? `suppdoc00${files?.length}` : files[0]?.file?.path;

    change(`${namePreffix}.documentName`, documentName);
    change(`${namePreffix}.documentDate`, files[0]?.file?.lastModifiedDate);
  }, [files, namePreffix]);

  useEffect(() => {
    const fetchSupportDocuments = async () => {
      try {
        setIsDocumentTypesLoading(true);
        const { data } = await api.kyc.entityManagement.getSupportingDocumentPackagesTypes();
        const filteredDocumentsByEntity = data?.filter(document => document?.entityType === pathnameEntityType);

        setSupportingPackages(
          filteredDocumentsByEntity.map(({ id, header, ...rest }) => ({
            label: header,
            value: id.toString(),
            id,
            ...rest,
          })),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setIsDocumentTypesLoading(false);
      }
    };
    fetchSupportDocuments();
  }, [pathnameEntityType]);

  const returnContent = () => {
    return (
      <div className="ickyc-additional-documents-form__content">
        <Field
          component={SelectField}
          name={`${namePreffix}.packageId`}
          label="Document Package"
          required
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
          validate={validators.required('Please Select Document Package')}
          options={supportingPackages}
          loading={isDocumentTypesLoading}
        />
        <Field component={TextareaField} name={`${namePreffix}.comment`} label="Comment" />
        {!noStatus && (
          <Field
            component={SelectField}
            name={`status`}
            initialValue={enums.HISTORY_EVENT_STATUSES.REVIEW}
            Trigger={<SelectTrigger />}
            Content={<SelectFilterContent />}
            options={mappedStatuses}
            label="Status"
          />
        )}

        {values?.documentEvent?.packageId && <PackageItemsFields packageId={values?.documentEvent?.packageId} />}
      </div>
    );
  };

  return (
    <div className="ickyc-additional-documents-form">
      {!noToggle && (
        <Field component={ToggleField} name="attachSupportingPackages" label="Attach Supporting Document" />
      )}
      {values.attachSupportingPackages && returnContent()}
      {noToggle && returnContent()}
    </div>
  );
}
AdditionalDocumentsForm.propTypes = {
  noToggle: PropTypes.bool,
  namePreffix: PropTypes.string,
  noStatus: PropTypes.bool,
};
AdditionalDocumentsForm.defaultProps = {
  noToggle: false,
  namePreffix: 'documentEvent',
  noStatus: false,
};
export default AdditionalDocumentsForm;
