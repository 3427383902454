import api from 'api';
import classNames from 'classnames';
import BareHeading from 'components/Headings/BareHeading';
import React, { useEffect, useState } from 'react';
import ProtectedComponent from 'router/components/ProtectedComponent';
import setClassSuffix from 'utilities/services/ClassManager';
import CasesCountTile from './components/CasesCountTile';
import EntitiesCountTile from './components/EntitiesCountTile';
import PortalsCountTile from './components/PortalsCountTile';
import UsersCountTile from './components/UsersCountTile';
import './styles.scss';
const DashboardPage = ({ className }) => {
  const baseClass = 'ickyc-dashboard-page';
  const setSuffix = setClassSuffix(baseClass);
  const classes = classNames(baseClass, 'ickyc-kyc-page', className);
  const [{ currentUsage, licenceLimits }, setLicenseLimits] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } = await api.administration.getLicenceLimits();
        setLicenseLimits(data);
        console.log({ data });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  console.log({ np: !!licenceLimits?.npPortals, le: !!licenceLimits?.lePortals });

  return (
    <div className={classes}>
      <BareHeading title="Dashboard" />
      <div className={setSuffix('__content')}>
        <UsersCountTile
          isLoading={isLoading}
          currentUsage={{
            platformUsers: currentUsage?.platformUsers,
            administrators: currentUsage?.administrators,
          }}
          licenceLimits={{
            platformUsers: licenceLimits?.platformUsers,
            administrators: licenceLimits?.administrators,
          }}
        />
        <ProtectedComponent licenceAccessKey="Entities">
          <EntitiesCountTile isLoading={isLoading} currentUsage={currentUsage?.entities} />
        </ProtectedComponent>
        <ProtectedComponent licenceAccessKey="Cases">
          <CasesCountTile isLoading={isLoading} currentUsage={currentUsage?.cases} />
        </ProtectedComponent>

        {(!!licenceLimits?.npPortals || !!licenceLimits?.lePortals || isLoading) && (
          <PortalsCountTile
            isLoading={isLoading}
            currentUsage={{
              npPortals: currentUsage?.npPortals,
              lePortals: currentUsage?.lePortals,
            }}
            licenceLimits={{
              npPortals: licenceLimits?.npPortals,
              lePortals: licenceLimits?.lePortals,
            }}
          />
        )}
      </div>
    </div>
  );
};
export default DashboardPage;
