import { useCallback, useEffect, useState } from 'react';
import utilities from '../utilities';
import useAdditionalParamsDep from './useAdditionalParamsDep';
import useEffectSkipFirst from './useEffectSkipFirst';

// TODO WRITE DOCs
export default (apiRequest, additonalParams, dataPropName = 'data', refetchData, allowFetch = true) => {
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({
    limit: 5,
    sortOrder: 1,
    sortCriteria: 'date',
    pageNumber: 1,
    ...additonalParams,
  });
  const paramsDep = useAdditionalParamsDep(additonalParams);

  useEffectSkipFirst(() => {
    setParams(old => ({ ...old, ...additonalParams }));
  }, [paramsDep]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchRecords = useCallback(
    async (showLoading = true) => {
      try {
        showLoading && setIsLoading(true);
        const { data: response } = await apiRequest(params);

        setPagination(response.paginationData);
        setRecords(dataPropName ? response[dataPropName] : response);
      } catch (err) {
        utilities.errorHandler(err);
      } finally {
        setIsLoading(false);
      }
    },
    [params, apiRequest],
  );

  useEffect(() => {
    allowFetch && fetchRecords();
  }, [fetchRecords, allowFetch]);

  // Handle refetch
  useEffect(() => {
    if (refetchData) {
      refetchData(fetchRecords, records);
    }
  }, [fetchRecords, refetchData, records]);

  const removeRow = useCallback((propName, targetValue) => {
    setRecords(prev => prev.filter(record => record[propName] !== targetValue));
    setPagination(prev => ({
      ...prev,
      total: prev.total - 1,
      tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) - 1,
    }));
  }, []);

  // TODO MERGE IN ONE
  const addRow = useCallback((newRecord, order = 'first') => {
    order === 'last' ? setRecords(prev => [...prev, newRecord]) : setRecords(prev => [newRecord, ...prev]);

    setPagination(prev => ({
      ...prev,
      total: prev.total + 1,
      tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) + 1,
    }));
  }, []);

  const addRows = useCallback(newRecords => {
    setRecords(prev => [...newRecords, ...prev]);
    setPagination(prev => ({
      ...prev,
      total: prev.total + newRecords.length,
      tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) + newRecords.length,
    }));
  }, []);

  const updateRowData = useCallback((propName, rowId, newData) => {
    setRecords(prev => {
      const isInTable = prev.some(row => row[propName] === rowId);

      if (!isInTable) {
        return prev;
      }

      return prev.map(row => (row[propName] === rowId ? { ...row, ...newData } : row));
    });
  }, []);

  const overrideTable = useCallback((tableRecords, tablePagination) => {
    if (tableRecords) {
      setRecords(tableRecords);
    }
    if (tablePagination) {
      setPagination(tablePagination);
    }
  }, []);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const afterAddRowFieldArray = useCallback(() => {
    setPagination(prev => ({
      ...prev,
      total: prev.total + 1,
      tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) + 1,
    }));
  }, []);

  const afterRemoveRowFieldArray = useCallback(() => {
    setPagination(prev => ({
      ...prev,
      total: prev.total - 1,
      tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) - 1,
    }));
  }, []);

  const replaceAllRowsWithOneRecord = useCallback(
    newRecord => {
      setRecords([newRecord]);
    },
    [setRecords],
  );

  return {
    isLoading,
    addRow,
    overrideTable,
    changeParams,
    removeRow,
    updateRowData,
    records,
    pagination,
    params,
    addRows,
    afterAddRowFieldArray,
    afterRemoveRowFieldArray,
    replaceAllRowsWithOneRecord,
    setRecords,
  };
};
