import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import RadioButtonField from 'components/RadioButtonField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import './styles.scss';

const DiscreteChoice = ({ multipleChoiceVersion }) => {
  const { mutators } = useForm();
  const addAnswer = useCallback(() => {
    mutators.push(`parameters.items`);
  }, [mutators]);

  const removeAnswer = useCallback(
    index => {
      mutators.remove(`parameters.items`, index);
    },
    [mutators],
  );
  return (
    <div className="ickyc-custom-descrete-choice">
      <div className="ickyc-custom-document-options">
        <span className="ickyc-custom-document-options--label">Question ID</span>
        <span>
          <Field name="id" component={InputField} preview></Field>
        </span>
      </div>
      <div className="ickyc-custom-descrete-choice--container">
        <div className="ickyc-custom-descrete-choice--container--button-container">
          <PrimaryButton onClick={addAnswer}>+ Add Answer</PrimaryButton>
        </div>
        <FieldArray name="parameters.items">
          {({ fields }) => {
            return fields.map((name, index) => (
              <div key={name} className="ickyc-custom-descrete-choice--container--item">
                <Field component={InputField} name={`${name}acceptedAnswer`} placeholder="Please set answer" />
                <div className="ickyc-custom-descrete-choice--container--item--radio">
                  {multipleChoiceVersion ? (
                    <Field component={CheckboxField} type="checkbox" name={`${name}preSelected`} label="Pre-Selected" />
                  ) : (
                    <Field
                      component={RadioButtonField}
                      name={`${name}preselected`}
                      parse={value => Number(value)}
                      type="radio"
                      value={!index ? 1 : 0}
                      label="Pre-Selected"
                    ></Field>
                  )}
                </div>
                <div className="ickyc-custom-descrete-choice--container--item--close-button">
                  <CloseButton
                    onClick={e => {
                      e.stopPropagation();
                      removeAnswer(index);
                    }}
                  />
                </div>
              </div>
            ));
          }}
        </FieldArray>
        <div className="ickyc-custom-descrete-choice--container--allow-other">
          <Field
            component={ToggleField}
            name="parameters.allowOther"
            label={`Allow User to Select "Other: " With Text Field to Clarify`}
          />
        </div>
      </div>
    </div>
  );
};
DiscreteChoice.propTypes = {
  multipleChoiceVersion: PropTypes.bool,
};
DiscreteChoice.defaultProps = {
  multipleChoiceVersion: false,
};
export default DiscreteChoice;
