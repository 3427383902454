import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BatchContext } from '../../../utilities/services/contexts';
import Table from '../Table';
import './styles.scss';

const CheckboxTable = ({
  values,
  onChange,
  TableControlsComponent,
  onControls,
  disabled,
  className,
  idPropName,
  onSelectedItemsChange,
  onLocalValsChanage,
  ...props
}) => {
  const [localValues, setLocalValues] = useState(values);

  useEffect(() => {
    if (onChange) onChange(localValues);
  }, [localValues, onChange]);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  const selectedAll = useMemo(() => {
    const selectedOptionsLength = localValues.filter(val => val.checked).length;
    return localValues.length > 0 && localValues.length === selectedOptionsLength;
  }, [localValues]);

  const listOfSelectedIDs = useMemo(
    () => localValues.filter(locVal => locVal?.checked).map(locVal => locVal[idPropName]),
    [localValues],
  );

  useEffect(() => {
    onSelectedItemsChange(listOfSelectedIDs);
  }, [listOfSelectedIDs]);

  const listOfSelectedEntities = useMemo(() => localValues.filter(locVal => locVal?.checked), [localValues]);

  useEffect(() => {
    onLocalValsChanage(listOfSelectedEntities);
  }, [listOfSelectedEntities]);

  const onSelectAll = useCallback(() => {
    setLocalValues(prev => prev.map(p => ({ ...p, checked: !selectedAll })));
  }, [selectedAll]);

  const applyToMultipleRows = useCallback(
    val => {
      setLocalValues(prev => {
        const newValues = prev.map(p =>
          p.checked ? { ...p, value: val instanceof Object ? { ...p.value, ...val } : val } : p,
        );
        if (onControls) {
          onControls(
            val,
            newValues.filter(v => v.checked).map(({ id }) => id),
          );
        }
        return newValues;
      });
    },
    [onControls],
  );

  const onCheckboxClick = useCallback(id => {
    setLocalValues(prev => prev.map(p => (p[idPropName] === id ? { ...p, checked: !p.checked } : p)));
  }, []);

  const onControlsClick = useCallback(
    (id, value) => {
      setLocalValues(prev => {
        const newValues = prev.map(p => (p[idPropName] === id ? { ...p, value } : p));
        if (onControls) {
          onControls(value, [id]);
        }
        return newValues;
      });
    },
    [onControls],
  );

  const disableControls = useMemo(() => localValues.every(lv => !lv.checked), [localValues]);

  const TableControls = useCallback(() => {
    return (
      <>
        <label>
          <input type="checkbox" checked={selectedAll} onChange={onSelectAll} disabled={disabled} /> Select All
        </label>
        <TableControlsComponent disabled={disableControls} onClick={applyToMultipleRows} />
      </>
    );
  }, [selectedAll, onSelectAll, disableControls, applyToMultipleRows, disabled]);
  return (
    <BatchContext.Provider value={{ listOfSelectedIDs }}>
      <Table
        values={localValues}
        {...props}
        onCheckboxClick={onCheckboxClick}
        onControlsClick={onControlsClick}
        tableControls={TableControlsComponent && TableControls}
        className={className || 'ickyc-checkbox-table'}
      />
    </BatchContext.Provider>
  );
};

export const FieldCheckBoxTable = ({ values, onChange, TableControlsComponent, className, ...props }) => {
  const [controlsValue, setControlValues] = useState();

  const selectedAll = useMemo(() => {
    const selectedOptionsLength = values.filter(val => val.checked).length;
    return values.length > 0 && values.length === selectedOptionsLength;
  }, [values]);

  const onSelectAll = useCallback(() => {
    onChange(values.map(p => ({ ...p, checked: !selectedAll })));
  }, [selectedAll, values, onChange]);

  const applyToMultipleRows = useCallback(
    val => {
      onChange(
        values.map(p => (p.checked ? { ...p, value: val instanceof Object ? { ...p.value, ...val } : val } : p)),
      );
    },
    [onChange, values],
  );

  const onCheckboxClick = useCallback(
    id => {
      onChange(values.map(p => (p.id === id ? { ...p, checked: !p.checked } : p)));
    },
    [values, onChange],
  );

  const onControlsClick = useCallback(
    (id, value) => {
      onChange(values.map(p => (p.id === id ? { ...p, value } : p)));
    },
    [onChange, values],
  );

  const disableControls = useMemo(() => values.every(lv => !lv.checked), [values]);

  const TableControls = () => {
    return (
      <>
        <label>
          <input type="checkbox" checked={selectedAll} onChange={onSelectAll} /> Select All
        </label>
        <TableControlsComponent
          disabled={disableControls}
          onClick={applyToMultipleRows}
          value={controlsValue}
          setValue={setControlValues}
        />
      </>
    );
  };

  return (
    <Table
      values={values}
      {...props}
      onCheckboxClick={onCheckboxClick}
      onControlsClick={onControlsClick}
      tableControls={TableControlsComponent && TableControls}
      className={className || 'ickyc-checkbox-table'}
    />
  );
};

FieldCheckBoxTable.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  withPagination: PropTypes.bool,
  handleParamsChange: PropTypes.func,
  headerData: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  tableControls: PropTypes.elementType,
  TableControlsComponent: PropTypes.elementType,

  rowControls: PropTypes.elementType,
  tableRow: PropTypes.elementType.isRequired,
  headerRow: PropTypes.elementType,
  updating: PropTypes.bool,
  shouldReset: PropTypes.bool,
  className: PropTypes.string,
  onControls: PropTypes.func,
};
FieldCheckBoxTable.defaultProps = {
  rowControls: undefined,
  tableControls: undefined,
  onChange: () => {},
  withPagination: false,
  values: [],
  pagination: {},
  handleParamsChange: () => {},
  headerData: {},
  options: [],
  updating: false,
  shouldReset: false,
  headerRow: null,
  TableControlsComponent: null,
  onControls: () => {},
  className: undefined,
};

CheckboxTable.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  withPagination: PropTypes.bool,
  handleParamsChange: PropTypes.func,
  headerData: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  tableControls: PropTypes.elementType,
  TableControlsComponent: PropTypes.elementType,
  rowControls: PropTypes.elementType,
  tableRow: PropTypes.elementType.isRequired,
  headerRow: PropTypes.elementType,
  updating: PropTypes.bool,
  shouldReset: PropTypes.bool,
  onControls: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  idPropName: PropTypes.string,
  onSelectedItemsChange: PropTypes.func,
  onLocalValsChanage: PropTypes.func,
};
CheckboxTable.defaultProps = {
  onChange: () => {},
  withPagination: false,
  tableControls: undefined,
  rowControls: undefined,
  values: [],
  pagination: {},
  handleParamsChange: () => {},
  headerData: {},
  options: [],
  updating: false,
  shouldReset: false,
  headerRow: null,
  TableControlsComponent: null,
  onControls: () => {},
  disabled: true,
  className: undefined,
  idPropName: 'id',
  onSelectedItemsChange: () => {},
  onLocalValsChanage: () => {},
};
export default CheckboxTable;
