import FilterFormsService from '../../../utilities/services/FilterFormsService';
import axiosInstance from '../../axiosInstance';
import legalEntity from './legalEntity';
import naturalPerson from './naturalPerson';

const ENDPOINTS = {
  requestClientUpdate: id => `/entities/natural-persons/${id}/request-client-update`,
  changeHistoryEventStatus: (entityId, eventHistoryId) => `/entities/${entityId}/events/${eventHistoryId}/event-status`,
  updateSupportingPackageDetails: (entityId, packageId) => `/entities/${entityId}/packages/${packageId}`,
  changeHistoryCorporateEventStatus: (entityId, eventHistoryId) =>
    `/entities/legal-entity/${entityId}/corporate-events/${eventHistoryId}/event-status`,
  changeHistoryDocumentsEventStatus: (entityId, eventHistoryId) =>
    `/entities/${entityId}/supporting-doc/${eventHistoryId}/status`,
  changeEntityStatus: id => `/entities/${id}/status`,

  createCSVExport: () => 'entities/csv-export',
  downloadCSVExport: complianceLogId => `entities/csv-export/${complianceLogId}`,

  getEntityDocumentsTable: id => `/entities/${id}/packages`,
  generatePersonalReport: id => `/entities/${id}/personal-report`,
  getCSVTemplate: () => '/entities/csv-import-template',
  getEntityDocumentsTableRecord: (entityId, documentId) => `/entities/${entityId}/packages/${documentId}`,
  getRiskScreeningSection: entityId => `/entities/${entityId}/risk-screening-section`,
  getSupportingPackages: () => `/client-account/packages`,
  getEntityRiskAndScore: id => `/entities/${id}/risk-and-score`,

  deleteEntitySoft: entityId => `/entities/${entityId}`,
  deleteHistoryRecordsSoft: (entityId, eventId) => `/entities/${entityId}/events/${eventId}`,
  deleteDocumentHistoryRecordsSoft: (entityId, eventId) => `/entities/${entityId}/supporting-doc/${eventId}`,

  attachEntitiesToDocuments: (entityId, documentId) => `/entities/${entityId}/packages/${documentId}`,
  detachEntitiesfromDocuments: (entityId, documentId) => `/entities/${entityId}/packages/${documentId}`,
  removeFileFromRecord: (entityId, documentId, fileId) =>
    `/entities/${entityId}/packages/${documentId}/files/${fileId}`,

  getEntityOpenCases: entityId => `case-management/${entityId}/cases`,
  createPersonalReportConfig: entityId => `entities/${entityId}/personal-report/config`,
  downloadReport: id => `/entities/reports/${id}`,
  getDomains: () => `/entities/natural-persons/request-client-update/domains`,
  getNpSettingsForRCU: (id, entityId) => `/entities/natural-persons/${entityId}/request-client-update/domains/${id}`,
  changeNextKYCReviewDate: id => `/entities/${id}/next-review`,
  getAllEntityTags: () => `/entities/get-tags`,

  getLegalEntityDomains: () => `/entities/legal-entities/request-client-update/domains`,
  getLESettingsForRCU: (id, entityId) => `/entities/legal-entities/${entityId}/request-client-update/domains/${id}`,
  requestClientUpdateLegalEntity: id => `/entities/legal-entities/${id}/request-client-update`,
  batchDeleteEntities: () => '/entities/batch',
  batchUpdateEntitiesAndRcuFromModal: () => '/entities/batch',
  revertRiskLevelToAutomatic: id => `/entities/${id}/revert-to-automatic`,
  getSupportingDocumentPackagesTypes: () => `/client-account/packages`,
};

const createCSVExport = (filters, parentEntityId, logId, searchEntity) => {
  return axiosInstance.post(ENDPOINTS.createCSVExport(), filters, {
    params: {
      parentEntityId,
      logId,
      searchEntity,
    },
  });
};
const downloadCSVExport = id => {
  return axiosInstance.get(ENDPOINTS.downloadCSVExport(id));
};
const getCSVTemplate = entityType => {
  return axiosInstance.get(ENDPOINTS.getCSVTemplate(), {
    params: {
      entityType,
    },
  });
};

const requestClientUpdate = (entityId, data, shouldForce) => {
  const params = shouldForce ? { cancelPendingVerification: true } : {};
  const filteredData = FilterFormsService.filterRcuModalData(data);
  return axiosInstance.post(ENDPOINTS.requestClientUpdate(entityId), filteredData, { params });
};

const getDomains = () => axiosInstance.get(ENDPOINTS.getDomains());

const changeHistoryEventStatus = (eventHistoryId, entityId, status) => {
  return axiosInstance.put(ENDPOINTS.changeHistoryEventStatus(entityId, eventHistoryId), {
    status,
  });
};

const changeHistoryCorporateEventStatus = (eventHistoryId, entityId, status) => {
  return axiosInstance.put(ENDPOINTS.changeHistoryCorporateEventStatus(entityId, eventHistoryId), {
    status,
  });
};

const changeHistoryDocumentsEventStatus = (eventHistoryId, entityId, status) => {
  return axiosInstance.put(ENDPOINTS.changeHistoryDocumentsEventStatus(entityId, eventHistoryId), {
    status,
  });
};
const changeEntityStatus = (id, data) => {
  return axiosInstance.put(ENDPOINTS.changeEntityStatus(id), {
    ...data,
  });
};

const getEntitiesTable = data => {
  const { filters, filterOn, ...params } = data;
  const filterData = FilterFormsService.normalizeEntityManagementFormData(filters, filterOn);
  return axiosInstance.post('/entities', filterData, {
    params,
  });
};

const getDocumentsTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getEntityDocumentsTable(entityId), { params });
};

const getDocumentsTableRecord = ({ entityId, documentId, ...params }) => {
  return axiosInstance.get(ENDPOINTS.getEntityDocumentsTableRecord(entityId, documentId), { params });
};

const getRiskScreeningSection = ({ entityId, ...params }) => {
  return axiosInstance.get(ENDPOINTS.getRiskScreeningSection(entityId), { params });
};

const getEntityRiskAndScore = entityId => {
  return axiosInstance.get(ENDPOINTS.getEntityRiskAndScore(entityId));
};

const createDocumentsTableRecord = (id, data) => {
  const formData = new FormData();

  formData.append('documentEvent', JSON.stringify(data.documentEvent));
  formData.append('status', data.status);

  Object.entries(data.items).forEach(([itemId, files]) => {
    files &&
      files.map(({ file }) => {
        formData.append('itemIds', itemId);
        formData.append('files', file);
      });
  });

  return axiosInstance.post(ENDPOINTS.getEntityDocumentsTable(id), formData);
};

const generatePersonalReport = entitytId => {
  return axiosInstance.get(ENDPOINTS.generatePersonalReport(entitytId), {
    responseType: 'blob',
  });
};

const deleteHistoryRecordsSoft = (entityId, eventId) =>
  axiosInstance.delete(ENDPOINTS.deleteHistoryRecordsSoft(entityId, eventId));

const deleteEntitySoft = (entityId, entityType) =>
  axiosInstance.delete(ENDPOINTS.deleteEntitySoft(entityId), {
    params: {
      entityType,
    },
  });

const attachEntitiesToDocuments = (entityId, documentId, entityIds) => {
  return axiosInstance.post(ENDPOINTS.attachEntitiesToDocuments(entityId, documentId), { entityIds });
};
const detachEntitiesFromDocuments = (entityId, documentId, entityToRemove) => {
  return axiosInstance.delete(ENDPOINTS.detachEntitiesfromDocuments(entityId, documentId), { id: entityToRemove });
};
const removeFileFromRecord = (entityId, documentId, fileId) => {
  return axiosInstance.delete(ENDPOINTS.detachEntitiesfromDocuments(entityId, documentId, fileId));
};

const deleteDocumentHistoryRecordsSoft = (entityId, eventId) =>
  axiosInstance.delete(ENDPOINTS.deleteDocumentHistoryRecordsSoft(entityId, eventId));

const getEntityOpenCases = entityId => axiosInstance.get(ENDPOINTS.getEntityOpenCases(entityId));

const createPersonalReportConfig = (entityId, data) =>
  axiosInstance.post(ENDPOINTS.createPersonalReportConfig(entityId), data);

const downloadReport = (id, forceGeneration) => {
  return axiosInstance.get(ENDPOINTS.downloadReport(id), { params: { forceGeneration }, responseType: 'blob' });
};

const getNpSettingsForRCU = (id, entityId, domain) => {
  return axiosInstance.get(ENDPOINTS.getNpSettingsForRCU(id, entityId), { params: { domain } });
};

const changeNextKYCReviewDate = (entityId, date) => {
  return axiosInstance.put(ENDPOINTS.changeNextKYCReviewDate(entityId), { date });
};
const getAllEntityTags = () => axiosInstance.get(ENDPOINTS.getAllEntityTags());

const getLegalEntityDomains = () => axiosInstance.get(ENDPOINTS.getLegalEntityDomains());

const getLESettingsForRCU = (id, entityId, domain) => {
  return axiosInstance.get(ENDPOINTS.getLESettingsForRCU(id, entityId), { params: { domain } });
};

const requestClientUpdateLegalEntity = (entityId, data, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.post(ENDPOINTS.requestClientUpdateLegalEntity(entityId), data, { params });
};

const batchDeleteEntities = data => axiosInstance.delete(ENDPOINTS.batchDeleteEntities(), { data });

const batchUpdateEntitiesAndRcuFromModal = (data, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.put(ENDPOINTS.batchUpdateEntitiesAndRcuFromModal(), data, { params });
};
const revertRiskLevelToAutomatic = entityId => axiosInstance.put(ENDPOINTS.revertRiskLevelToAutomatic(entityId));

const getSupportingPackages = () => {
  return axiosInstance.get(ENDPOINTS.getSupportingPackages());
};

const updateSupportingPackageDetails = ({ entityId, eventHistoryId, ...rest }) => {
  return axiosInstance.put(ENDPOINTS.updateSupportingPackageDetails(entityId, eventHistoryId), { ...rest });
};

const getSupportingDocumentPackagesTypes = () => {
  return axiosInstance.get(ENDPOINTS.getSupportingDocumentPackagesTypes());
};

export default {
  deleteEntitySoft,
  deleteHistoryRecordsSoft,
  getRiskScreeningSection,
  changeEntityStatus,
  changeHistoryEventStatus,
  changeHistoryCorporateEventStatus,
  getEntitiesTable,
  getEntityRiskAndScore,
  requestClientUpdate,
  legalEntity,
  naturalPerson,
  getDocumentsTable,
  getDocumentsTableRecord,
  createDocumentsTableRecord,
  generatePersonalReport,
  createCSVExport,
  downloadCSVExport,
  removeFileFromRecord,
  getCSVTemplate,
  attachEntitiesToDocuments,
  detachEntitiesFromDocuments,
  changeHistoryDocumentsEventStatus,
  deleteDocumentHistoryRecordsSoft,
  getEntityOpenCases,
  createPersonalReportConfig,
  downloadReport,
  getDomains,
  getNpSettingsForRCU,
  changeNextKYCReviewDate,
  getAllEntityTags,
  getLegalEntityDomains,
  getLESettingsForRCU,
  requestClientUpdateLegalEntity,
  batchDeleteEntities,
  batchUpdateEntitiesAndRcuFromModal,
  revertRiskLevelToAutomatic,
  getSupportingPackages,
  updateSupportingPackageDetails,
  getSupportingDocumentPackagesTypes,
};
