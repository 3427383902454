import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../../api';
import Accordion from '../../../../../../components/Accordion';
import OutlineButton from '../../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import IComplyForm from '../../../../../../components/Form/IComplyForm';
import Table from '../../../../../../components/Tables/Table';
import useModalHandler from '../../../../../../hooks/useModalHandler';
import useTable from '../../../../../../hooks/useTable';
import useViewRelatedEventsListener from '../../../../../../hooks/useViewRelatedEventsListener';
import bus from '../../../../../../modules/bus';
import enums from '../../../../../../utilities/enums';
import DateManager from '../../../../../../utilities/services/DateManager';
import { EntityContext, PermissionGroupContext } from '../../../../../../utilities/services/contexts';
import AdditionalDocumentsForm from '../../../components/AdditionalDocumentsForm';
import HistoryRecordTableHeader from '../../../components/HistoryRecordTableHeader';
import LegalEntityInformationForm from '../../../components/LegalEntityInformationForm';
import RequestClientUpdateLegalEntityModal from '../../../components/RequestClientUpdateLegalEntityModal';
import CorporateInformationTableRow from './components/CorporateInformationTableRow';

const LegalEntityInformationSection = ({ data, updateEntityInfo, badge }) => {
  const containerRef = useRef(null);

  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: editMode, open: openEditForm, close: closeEditForm } = useModalHandler();
  const { entityId } = useContext(EntityContext);

  const {
    isLoading,
    removeRow,
    records,
    changeParams,
    pagination,
    params,
    overrideTable,
    addRow,
    updateRowData,
  } = useTable(api.kyc.entityManagement.legalEntity.getInformationTable, { entityId });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_CORPORATE_TABLE, overrideTable, containerRef);

  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData({ corporateInformation: data, attachSupportingPackages: false });
  }, [data]);

  const handleSave = async (values, form) => {
    const { attachSupportingPackages, corporateInformation, documentEvent, items, status: eventStatus } = values;
    const dataToSubmit = {};

    if (attachSupportingPackages) {
      dataToSubmit.documentEvent = documentEvent;
      dataToSubmit.items = items;
    }
    corporateInformation.jurisdictionOfTaxResidenceStateProvince = 0;
    corporateInformation.jurisdictionOfIncorporationStateProvince = 0;
    corporateInformation.jurisdictionOfDomicileStateProvince = 0;
    corporateInformation.formationDate = DateManager.toBekend(corporateInformation.formationDate);
    dataToSubmit.corporateInformation = corporateInformation;
    dataToSubmit.status = eventStatus;

    return api.kyc.entityManagement.legalEntity
      .updateInformation(entityId, dataToSubmit)
      .then(response => {
        const { documentInfo, personalInfo, commentDto } = response.data;
        toastr.success('Succesfully Updated Legal Entity Information');
        if (personalInfo) {
          addRow(personalInfo);
        }
        if (attachSupportingPackages && documentInfo) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, documentInfo);
        }
        if (commentDto) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, commentDto);
        }
        if (eventStatus === enums.HISTORY_EVENT_STATUSES.ACCEPTED) {
          const updateData = {
            countryId: dataToSubmit.corporateInformation.jurisdictionOfIncorporationId,
            stateProvinceId: dataToSubmit.corporateInformation.jurisdictionOfIncorporationStateProvince,
            name: dataToSubmit.corporateInformation.name,
          };
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, updateData);
          updateEntityInfo(dataToSubmit.corporateInformation);
        } else {
          form.reset();
        }
        closeEditForm();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to update Legal Entity' };
      });
  };

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventHistoryId', eventId);
    },
    [removeRow],
  );

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data: newData }) => {
      updateRowData('eventHistoryId', eventHistoryId, newData);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  useEffect(() => {
    const updateHistoryEventStatus = rowData => {
      addRow(rowData);
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_CORPORATE_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_CORPORATE_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [addRow]);

  const { edit: canEdit, delete: canDelete, requestClientUpdate: canRCU } = useContext(PermissionGroupContext);

  const TableControls = () => {
    return (
      <>
        <h3>History</h3>
        <div>
          {canEdit && <OutlineButton onClick={openEditForm}>Edit</OutlineButton>}
          {canRCU && <PrimaryButton onClick={openRCU}> Request Client Update</PrimaryButton>}
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Entity Information"
      withBadge
      badge={badge}
      accordionOpen
      accented
      accordionindex={enums.ACCORDION_INDEXES.CORPORATE_INFORMATION}
    >
      <div ref={containerRef}>
        <IComplyForm
          initialValues={initialData}
          onSubmit={handleSave}
          onDiscardClick={closeEditForm}
          showControls={editMode}
          mutators={{ ...arrayMutators }}
          submitAcceptButtonText="Save and Accept"
          withTwoSubmitButtons
          noDiscardValidation
        >
          <LegalEntityInformationForm preview={!editMode} responsive />
          {editMode && <AdditionalDocumentsForm noStatus />}
        </IComplyForm>

        <div className="ickyc-content-breaker" />
        {rcuOpen && (
          <RequestClientUpdateLegalEntityModal
            hideModal={closeRCU}
            containerRef={containerRef}
            identityVerificationEnabled
          />
        )}
        <Table
          tableControls={TableControls}
          headerRow={HistoryRecordTableHeader}
          tableRow={CorporateInformationTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          updating={isLoading}
          onRemove={handleHistoryRecordDelete}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            hasSourceColumn: true,
            onClick: changeParams,
            withDelete: canDelete,
          }}
          className="ickyc-history-record-table"
          containerRef={containerRef}
        />
      </div>
    </Accordion>
  );
};

LegalEntityInformationSection.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    doingBusinessAs: PropTypes.string,
    businessNumber: PropTypes.string,
    jurisdictionOfIncorporationId: PropTypes.number,
    jurisdictionOfTaxResidenceId: PropTypes.number,
    jurisdictionOfDomicileId: PropTypes.number,
    alsoKnownAs: PropTypes.string,
    formerlyKnownAs: PropTypes.string,
    natureOfBusiness: PropTypes.string,
    dunsNumber: PropTypes.string,
    leiNumber: PropTypes.string,
  }).isRequired,
  badge: PropTypes.number,
  updateEntityInfo: PropTypes.func,
};
LegalEntityInformationSection.defaultProps = {
  badge: 0,
  updateEntityInfo: () => {},
};
export default LegalEntityInformationSection;
