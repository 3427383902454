import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import React from 'react';
import TableHeaders from 'utilities/services/TableHeaders';
import DocumentPackageTableRow from './components/DocumentPackageTableRow';
import './styles.scss';

const DocumentsPackageTable = ({ items }) => {
  return (
    <Table
      headerRow={TableHeader}
      tableRow={DocumentPackageTableRow}
      withPagination
      headerData={{
        items: TableHeaders.DocumentsPackageTableHeader,
      }}
      className="ickyc-documents-package-table"
      values={items}
    />
  );
};
export default DocumentsPackageTable;
