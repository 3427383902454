import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * Custom content Radio Button
 *
 * @param {string} name
 * @param {string} label
 * @param {string} icon
 * @param {boolean} inline
 * @param {boolean} disabled
 * @param {func} onChange - handles click on radio button
 * @param {string} value - input value
 * @param {string} checked - checked value
 *
 */
const Checkbox = ({ disabled, inline, classNameSuffix, label, ...rest }) => {
  const classes = classNames({
    'ickyc-checkbox': true,
    'ickyc-checkbox--disabled': disabled,
    'ickyc-checkbox--inline': inline,
    [`ickyc-checkbox${classNameSuffix}`]: classNameSuffix,
  });

  return (
    <label className={classes}>
      <input checked {...rest} type="checkbox" />
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  center: PropTypes.bool,
  ickycTable: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),

  icon: PropTypes.string,
  classNameSuffix: PropTypes.string,
};

Checkbox.defaultProps = {
  center: false,
  ickycTable: false,
  disabled: false,
  inline: false,
  onChange: () => {},
  label: '',
  icon: undefined,
  classNameSuffix: undefined,
  value: undefined,
};

export default Checkbox;
